<template>
	<div class="total drag" v-draw id="CfbParameterSmoke2">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="title">{{projectData.project}}_参数02_2</div>
				<div class="icon" @click="closeCompon"></div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				再热蒸汽温度控制模型（烟气挡板）
			</div>
		</div>
		<!-- 主体内容 -->
		<div class="context flex">
			<div class="one">
				<div class="shadow shadow1 float1">
					<div class="flex">
						<div class="column1">C05-SP</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.ZQTSP_B2,'C05-SP','ZQTSP_B2','MCSQIWEN')">
							{{infoList.MCSQIWEN.ZQTSP_B2}}
						</div>
					</div>
				</div>
				<div class="shadow shadow1">
					<div class="flex">
						<div class="column1">C05-PV</div>
						<div class="column2" @click="toCompon(2,'BYMNL10','MCSBY','BYMNL10VV1','再热蒸汽高过出口温度均值')"
							@dblclick="Cclick(infoList.MCSBY.BYMNL10,'BYMNL10','MCSBY','BYMNL10VV1')">
							{{infoList.MCSBY.BYMNL10}}
						</div>
					</div>
				</div>
			</div>
			<div class="two">
				<div class="bg1">
					<div class=" flex">
						<div class="buttonr float1" :style="{
								background:infoList.MCSRPQ__p__RSF0J && !infoList.MCSRPQ__p__RSF0J.SP
							    ? '#2AFC30'
						    	: 'red',}" @click="infoList.MCSRPQ__p__RSF0J
								? toDetail(3,'RSF0J','MCSRPQ__p__RSF0J','','氨水流量软伺服'): ''">
							R
						</div>
						<div class="button float2">
							<div class="button_word" @click="toIpt(infoList.MCSRPQ.RSF0JSEL,'按钮','RSF0JSEL','MCSRPQ')">
								{{infoList.MCSRPQ.RSF0JSEL ? "投用" : "切除"}}
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="word">水煤比前馈</div>
						<div class="buttonr float3" :style="{
								background:infoList.MCSRPQ__p__QK01 && !infoList.MCSRPQ__p__QK01.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCSRPQ__p__QK01
								    ? toDetail(2,'QK01','MCSRPQ__p__QK01','','电负荷前馈'): ''">
							K
						</div>
						<div class="button float4">
							<div class="button_word" @click="toIpt(infoList.MCSYCL.SEL22,'按钮','SEL22','MCSYCL')">
								{{infoList.MCSYCL.SEL22 ? "投用" : "切除"}}
							</div>
						</div>
					</div>
					<div class="buttonx float5" :style="{
						background:
						infoList.MCSMAN__p__MAN33.RM  == 1 || infoList.MCSMAN__p__MAN34.RM == 1 || infoList.MCSMAN__p__MAN35.RM == 1 || infoList.MCSMAN__p__MAN36.RM == 1 ? '#2AFC30' : 'red'
						}" @click=" toCompon(0,'CFB_MANQWZ',4)">A</div>
				</div>

			</div>
			<div class="three">
				<div class="float1">
					<div class="shadow shadow2 flex">
						<div class="column1">C01-SP</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.ZQTSP21,'C01-SP','ZQTSP21','MCSQIWEN')">
							{{infoList.MCSQIWEN.ZQTSP21}}
						</div>
					</div>
					<div class="shadow shadow2 flex">
						<div class="column1">C01-PV</div>
						<div class="column3" @click="toCompon(2,'QWSJPV1','MCSYCL','QWSJPV1VV1','一级左低过出口蒸汽温度')"
							@dblclick="Cclick(infoList.MCSYCL.QWSJPV1,'QWSJPV1','MCSYCL','QWSJPV1VV1')">
							{{infoList.MCSYCL.QWSJPV1}}
						</div>
					</div>
					<div class="shadow shadow2 flex">
						<div class="column1">C01-TC</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.QWTC1,'C01-TC','QWTC1','MCSQIWEN')">
							{{infoList.MCSQIWEN.QWTC1}}
						</div>
					</div>
				</div>
				<div class="float2">
					<div class="shadow shadow2 flex">
						<div class="column1">C02-SP</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.ZQTSP22,'C02-SP','ZQTSP22','MCSQIWEN')">
							{{infoList.MCSQIWEN.ZQTSP22}}
						</div>
					</div>
					<div class="shadow shadow2 flex">
						<div class="column1">C02-PV</div>
						<div class="column3" @click="toCompon(2,'QWSJPV2','MCSYCL','QWSJPV2VV1','一级右低过出口蒸汽温度')"
							@dblclick="Cclick(infoList.MCSYCL.QWSJPV2,'QWSJPV2','MCSYCL','QWSJPV2VV1')">
							{{infoList.MCSYCL.QWSJPV2}}
						</div>
					</div>
					<div class="shadow shadow2 flex">
						<div class="column1">C02-TC</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.QWTC2,'C02-TC','QWTC2','MCSQIWEN')">
							{{infoList.MCSQIWEN.QWTC2}}
						</div>
					</div>
				</div>
				<div class="float3">
					<div class="shadow shadow2 flex">
						<div class="column1">C03-SP</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.ZQTSP_B2,'C03-SP','ZQTSP_B2','MCSQIWEN')">
							{{infoList.MCSQIWEN.ZQTSP_B2}}
						</div>
					</div>
					<div class="shadow shadow2 flex">
						<div class="column1">C03-PV</div>
						<div class="column3" @click="toCompon(2,'QWSJPV3','MCSYCL','QWSJPV3VV1','二级左高过出口蒸汽温度')"
							@dblclick="Cclick(infoList.MCSYCL.QWSJPV3,'QWSJPV3','MCSYCL','QWSJPV3VV1')">
							{{infoList.MCSYCL.QWSJPV3}}
						</div>
					</div>
					<div class="shadow shadow2 flex">
						<div class="column1">C03-TC</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.QWTC3,'C03-TC','QWTC3','MCSQIWEN')">
							{{infoList.MCSQIWEN.QWTC3}}
						</div>
					</div>
				</div>
				<div class="float4">
					<div class="shadow shadow2 flex">
						<div class="column1">C04-SP</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.ZQTSP_B2,'C04-SP','ZQTSP_B2','MCSQIWEN')">
							{{infoList.MCSQIWEN.ZQTSP_B2}}
						</div>
					</div>
					<div class="shadow shadow2 flex">
						<div class="column1">C04-PV</div>
						<div class="column3" @click="toCompon(2,'QWSJPV4','MCSYCL','QWSJPV4VV1','二级右高过出口蒸汽温度')"
							@dblclick="Cclick(infoList.MCSYCL.QWSJPV4,'QWSJPV4','MCSYCL','QWSJPV4VV1')">
							{{infoList.MCSYCL.QWSJPV4}}
						</div>
					</div>
					<div class="shadow shadow2 flex">
						<div class="column1">C04-TC</div>
						<div class="column2" @click="toIpt(infoList.MCSQIWEN.QWTC4,'C04-TC','QWTC4','MCSQIWEN')">
							{{infoList.MCSQIWEN.QWTC4}}
						</div>
					</div>
				</div>
			</div>
			<div class="four">
				<div class="bg2">
					<div>
						<div class="buttonx float1" :style="{
							background:infoList.MCSXK__p__XK0K && !infoList.MCSXK__p__XK0K.RM
						    ? '#2AFC30'
						    : 'red',
							}" @click="infoList.MCSXK__p__XK0K
							? toDetail(1, 'XK0K', 'MCSXK__p__XK0K', '', '左1烟气挡板调节'): ''">
							X
						</div>
						<div class="flex">
							<div class="buttonr float2" :style="{
								background:infoList.MCSRPQ__p__QK0A && !infoList.MCSRPQ__p__QK0A.TS
							    ? '#2AFC30'
							    : 'red',}" @click="infoList.MCSRPQ__p__QK0A
								    ? toDetail(2,'QK0A','MCSRPQ__p__QK0A','','QK0A设定值'): ''">
								K
							</div>
							<div class="button float3">
								<div class="button_word"
									@click="toIpt(infoList.MCSRPQ.QK0ASEL,'按钮','QK0ASEL','MCSRPQ')">
									{{infoList.MCSRPQ.QK0ASEL ? "投用" : "切除"}}
								</div>
							</div>
						</div>
					</div>
					<div class="float4">
						<div class="buttonx float1" :style="{
							background:infoList.MCSXK__p__XK0L && !infoList.MCSXK__p__XK0L.RM
						    ? '#2AFC30'
						    : 'red',
							}" @click="infoList.MCSXK__p__XK0L
							? toDetail(1, 'XK0L', 'MCSXK__p__XK0L', '', '左1烟气挡板调节'): ''">
							X
						</div>
						<div class="flex">
							<div class="buttonr float2" :style="{
								background:infoList.MCSRPQ__p__QK0B && !infoList.MCSRPQ__p__QK0B.TS
							    ? '#2AFC30'
							    : 'red',}" @click="infoList.MCSRPQ__p__QK0B
								    ? toDetail(2,'QK0B','MCSRPQ__p__QK0B','','QK0B设定值'): ''">
								K
							</div>
							<div class="button float3">
								<div class="button_word"
									@click="toIpt(infoList.MCSRPQ.QK0BSEL,'按钮','QK0BSEL','MCSRPQ')">
									{{infoList.MCSRPQ.QK0BSEL ? "投用" : "切除"}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="bg2 float5">
					<div>
						<div class="buttonx float1" :style="{
							background:infoList.MCSXK__p__XK0A && !infoList.MCSXK__p__XK0A.RM
						    ? '#2AFC30'
						    : 'red',
							}" @click="infoList.MCSXK__p__XK0A
							? toDetail(1, 'XK0A', 'MCSXK__p__XK0A', '', '右1烟气挡板调节'): ''">
							X
						</div>
						<div class="flex">
							<div class="buttonr float2" :style="{
								background:infoList.MCSRPQ__p__QK05 && !infoList.MCSRPQ__p__QK05.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCSRPQ__p__QK05
								    ? toDetail(2,'QK05','MCSRPQ__p__QK05','','二级左再热前馈'): ''">
								K
							</div>
							<div class="button float3">
								<div class="button_word"
									@click="toIpt(infoList.MCSRPQ.QK05SEL,'按钮','QK05SEL','MCSRPQ')">
									{{infoList.MCSRPQ.QK05SEL ? "投用" : "切除"}}
								</div>
							</div>
						</div>
					</div>
					<div class="float4">
						<div class="buttonx float1" :style="{
							background:infoList.MCSXK__p__XK0B && !infoList.MCSXK__p__XK0B.RM
						    ? '#2AFC30'
						    : 'red',
							}" @click="infoList.MCSXK__p__XK0B
							? toDetail(1, 'XK0B', 'MCSXK__p__XK0B', '', '右2烟气挡板调节'): ''">
							X
						</div>
						<div class="flex">
							<div class="buttonr float2" :style="{
								background:infoList.MCSRPQ__p__QK06 && !infoList.MCSRPQ__p__QK06.TS
						        ? '#2AFC30'
						        : 'red',}" @click="infoList.MCSRPQ__p__QK06
								    ? toDetail(2,'QK06','MCSRPQ__p__QK06','','二级右再热前馈'): ''">
								K
							</div>
							<div class="button float3">
								<div class="button_word"
									@click="toIpt(infoList.MCSRPQ.QK06SEL,'按钮','QK06SEL','MCSRPQ')">
									{{infoList.MCSRPQ.QK06SEL ? "投用" : "切除"}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="five">
				<div class="shadow shadow3 float1 flex">
					<div class="column1">C01-A01</div>
					<div class="column3" @click="toCompon(2,'BCSZRAO1_B','MCSAO','BCSZRAO1_BVV1')"
						@dblclick="Cclick(infoList.MCSAO.BCSZRAO1_B,'BCSZRAO1_B','MCSAO','BCSZRAO1_BVV1')">
						{{infoList.MCSAO.BCSZRAO1_B}}
					</div>
				</div>
				<div class="shadow shadow3 float2 flex">
					<div class="column1">C02-A02</div>
					<div class="column3" @click="toCompon(2,'BCSZRAO2_B','MCSAO','BCSZRAO2_BVV1')"
						@dblclick="Cclick(infoList.MCSAO.BCSZRAO2_B,'BCSZRAO2_B','MCSAO','BCSZRAO2_BVV1')">
						{{infoList.MCSAO.BCSZRAO2_B}}
					</div>
				</div>
				<div class="shadow shadow3 float3 flex">
					<div class="column1">C03-A03</div>
					<div class="column3" @click="toCompon(2,'BCSZRAO3_B','MCSAO','BCSZRAO3_BVV1')"
						@dblclick="Cclick(infoList.MCSAO.BCSZRAO3_B,'BCSZRAO3_B','MCSAO','BCSZRAO3_BVV1')">
						{{infoList.MCSAO.BCSZRAO3_B}}
					</div>
				</div>
				<div class="shadow shadow3 float4 flex">
					<div class="column1">C04-A04</div>
					<div class="column3" @click="toCompon(2,'BCSZRAO4_B','MCSAO','BCSZRAO4_BVV1')"
						@dblclick="Cclick(infoList.MCSAO.BCSZRAO4_B,'BCSZRAO4_B','MCSAO','BCSZRAO4_BVV1')">
						{{infoList.MCSAO.BCSZRAO4_B}}
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<Firstcontrol :infoList='infoList' :isIndex='isIndex' :historyname='Firstcontrolname'
			:titname='Firstcontroltitname' :node='Firstcontrolnode' v-if="isFshow" @sendStatus='isFshow=false'>
		</Firstcontrol>
		<Rsf v-if="isRshow" @sendStatus='isRshow=false' :titname='Rsftitname' :infoList='infoList'
			:historyname='Rsfname' :node='Rsfnode'></Rsf>
		<ParameterYh v-if="isComShow" :infoList='infoList' @sendStatus='isshowfase'></ParameterYh>
		<manyManual v-if="manyManual" :titname="Manualtitname" :numM="Manualnode" @sendStatus="isClose"
			:infoList="infoList"></manyManual>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
	import Rsf from "@/components/RSF.vue"; //阮伺服组件
	import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
	import manyManual from "@/components/manyManual.vue"; //多手操器
	export default {
		name: "CfbParameter3",
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		components: {
			inputVal,
			Historical,
			Firstcontrol,
			Rsf,
			ParameterYh,
			manyManual
		},
		data: () => {
			return {
				chName: '',
				manyManual: '',
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
					console.log(this.infoList)
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))

		},
		methods: {
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'Cfbkfirstcontol'
							break
						case 3:
							pathname = 'CfbRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'Parameter022', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
					case 'manyManual':
						return this.manyManual = val1
				}
			},
			toIpt(data, name, historyname, node) {

				this.$refs.inputVal.open(data, name, historyname, node)
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.manyManual = true, this.Manualtitname = name, this.Manualnode = name2
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	#CfbParameterSmoke2 {
		width: 53vw;
		height: 67vh;
		background-image: url("~@/assets/images/CfbBoiler/para031_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -33.5vh 0 0 -26.5vw;
		z-index: 999;

		.title-hang {
			width: 53vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			margin-left: 15vw;
		}

		.title {
			width: 19vw;
			height: 4vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			margin-left: 1.5vw;
		}

		.icon {
			width: 1.8vw;
			height: 3.2vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 15vw;
			margin-top: 1.8vh;
		}

		.main {
			width: 53vw;
			height: 2vh;
			margin-top: 3vh;

			.main_title {
				font-family: PingFang-SC-Regular;
				font-size: 3vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.context {
			width: 40.7vw;
			height: 49.7vh;
			margin-top: 4.5vh;
			margin-left: 5.8vw;
			font-family: PingFang-SC-Regular;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

			.shadow {
				width: 7vw;
				background-color: #001b2860;
				box-shadow: 0vw 0vw 0vw 0vw #15384c;
				border-radius: 1px;
				border: solid 1px #236f8d;
				font-size: 1.2vh;
				line-height: 2.5vh;

				.column1 {
					width: 4vw;
					color: #8aeaff;
					margin-left: 0.3vw;
				}

				.column2 {
					width: 2vw;
					color: #00ffb4;
					text-align: right;
				}

				.column3 {
					width: 2vw;
					color: #00e4ff;
					text-align: right;
				}
			}

			.buttonx {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #00e4ff;
				color: white;
			}

			.buttonr {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #04ff57;
				color: white;
			}

			.button {
				width: 3vw;
				height: 2vh;
				background-color: #22fff7;
				border-radius: 0vh;
				font-family: PingFang-SC-Regular;
				font-size: 1vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 0vh;

				.button_word {
					width: 3vw;
					text-align: center;
					color: #0a4c62;
				}
			}

			.one {
				.shadow1 {
					height: 2.5vh;
				}

				.float1 {
					margin-top: -0.8vh;
					margin-bottom: 0.8vh;
					margin-left: 0vw;
				}
			}

			.two {
				.bg1 {
					width: 11.3vw;
					height: 13.5vh;
					background-image: url("~@/assets/images/CfbBoiler/para021(1).png");
					background-size: 100% 100%;

					.float1 {
						margin-left: 1.3vw;
						margin-top: -1vh;
					}

					.float2 {
						margin-left: 0.5vw;
						margin-top: 3.5vh;
					}

					.word {
						font-size: 1.9vh;
						line-height: 2.6vh;
						color: #d5fffe;
						margin-top: 6vh;
						margin-left: -5vw;
					}

					.float3 {
						margin-left: 1vw;
						margin-top: 6vh;
					}

					.float4 {
						margin-left: 0.5vw;
						margin-top: 6.2vh;
					}

					.float5 {
						margin-left: 10.5vw;
						margin-top: 20.6vh;
					}
				}

			}

			.three {
				.shadow2 {
					height: 2.5vh;
					margin-top: 0vh;
					margin-left: 0vw;
				}

				.float1 {
					margin-top: -0.8vh;
				}

				.float2 {
					margin-top: 4.5vh;
				}

				.float3 {
					margin-top: 5.6vh;
				}

				.float4 {
					margin-top: 4.5vh;
				}
			}

			.four {
				margin-top: -1.2vh;

				.bg2 {
					width: 10.2vw;
					height: 19.5vh;
					background-image: url("~@/assets/images/CfbBoiler/para021(2).png");
					background-size: 100% 100%;
					padding: 0.1vw;

					.float1 {
						margin-top: 0.0vh;
						margin-left: 1.2vw;
					}

					.float2 {
						margin-top: 2.8vh;
						margin-left: 1.2vw;
					}

					.float3 {
						margin-top: 1.1vh;
						margin-left: 0.2vw;
					}

					.float4 {
						margin-top: 4.6vh;
					}
				}

				.float5 {
					margin-top: 6.3vh;
				}
			}

			.five {
				.shadow3 {
					height: 2.5vh;
				}

				.float1 {
					margin-top: -0.8vh;
					margin-left: 0vw;
				}

				.float2 {
					margin-top: 9.8vh;
					margin-left: 0vw;
				}

				.float3 {
					margin-top: 10.9vh;
					margin-left: 0vw;
				}

				.float4 {
					margin-top: 9.7vh;
					margin-left: 0vw;
				}
			}
		}
	}
</style>
