import Config from "@/utils/config"
import {
	mapGetters
} from "vuex"


export default {
	name: "index",
	components: {
	},
	data: () => {
		return {
			code:'',
			timer:null,
			loadingAd: true,
			loadingFloor: true,
			isMshow:false,
			isFshow:false,
			isPshow:false,
			isPshow01:false,
			isPshow11:false,
			isPshow21:false,
			isPshow31:false,
			isPshow41:false,
			isIndex:'',
			info:{
				code:'',
				device:''
			},
			isComShow:false,
			deviceType:{},
			authInfo:[],
			UserInfo:[],
			maskbok:false
		}
	},
	watch: {
	},
	created() {
		this.deviceType = JSON.parse(localStorage.getItem("deviceType"))
		this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
		this.authInfo = JSON.parse(localStorage.getItem("autharr"))
		this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll)
		
	},
	computed: {
	},
	methods: {
		DataJson(data){
			let strNode = data[0]
			let strMark = data[1]
			  this.$nextTick(() => {
			 this.infoList[strNode][strMark] = data[2]     
			});
		  },
		// 打开先控多窗口
		toDetail(key,name,nodename,Aname,titname){
			if(!this.authInfo[1]){
				let str = this.authInfo[0]
				// 判断是否为调试还是查看权限
				if (str.charAt(str.length-1) == "r") {
					this.$message.error('暂无调试权限');
				}
			}else{
				
				let pathname=""
				switch(key){
					case 1:
					pathname = 'firstcontol'
					break
					case 2:
				    pathname = 'Cfbkfirstcontol'
					break
					case 3:
				    pathname = 'CfbRfirstcontol'
					break
				}
				const newurl = this.$router.resolve({name: pathname, query: {Firstcontrolname: name,Firstcontrolnode:nodename,Firstcontroltitname:titname}})
				window.open(newurl.href,'_blank')
			}
		},
		// 打开手操器组件
		toCompon(key,name,nodename,Aname,titname){
			if(!this.authInfo[1] && key != 2){
				let str = this.authInfo[0]
				// 判断是否为调试还是查看权限
				if (str.charAt(str.length-1) == "r") {
					this.$message.error('暂无调试权限');
				}
			}else{
				this.isIndex = key
				this.Aname = Aname
				this.historyname = name,
				this.nodename = nodename
				clearTimeout(this.grouptime);
				
				this.grouptime=setTimeout(() =>{
					this.maskbok = true
					switch(key){
						case 0:
						return this.manyManual = true,this.Manualtitname = name,this.Manualnode= nodename
						case 1 :
						return  this.isFshow = true,this.Firstcontrolname = name,this.Firstcontrolnode = nodename,this.Firstcontroltitname = titname 
						case 2:
						return 	this.isHshow = true,this.chName = titname,this.maskbok = false
						case 3:
						return  this.Parameter022 = true
						case 4:
						return  this.Parameter08 = true
						case 5:
						return  this.Parameter04 = true
						case 6:
						return  this.Parameter02 = true
						case 7:
						return  this.Parameter07 = true
						case 8:
						return  this.Parameter06 = true
						case 9:
						return  this.Parameter05 = true
						case 10:
						return  this.Parameter03 = true
						case 11:
						return  this.Parameter01 = true
					}
				},300);
			}
			
		},
		isClose(val,val1){
			this.maskbok = false
			switch(val){
				case 'manyManual':
				return this.manyManual = val1
				case 'Evaluate' :
				case (this.flag == 2):	
				return  this.flag = 0,this.$bus.$emit("footersendMsg", 0);
				case 'Security' :
				case (this.flag == 1):	
				return  this.flag = 0,this.$bus.$emit("footersendMsg", 0)
				case 'Switchpage' :
				case (this.flag == 3):	
				return  this.flag = 0,this.$bus.$emit("footersendMsg", 0)
				case 'Firstcontrol':
				return this.isFshow = val1	
				case 'Historical':
				return this.isHshow = val1
				case 'CfbCommonSetting':
				case (this.flag == 5):	
				return  this.flag = 0,this.$bus.$emit("footersendMsg", 0)
				case 'Cfbserve':
				case (this.flag == 7):	
				return  this.flag = 0,this.$bus.$emit("footersendMsg", 0)
				case 'Parameter08':
				return this.Parameter08 = val1	
				case 'Parameter022':
				return this.Parameter022 = val1
				case 'Parameter04':
				return this.Parameter04 = val1
				case 'Parameter02':
				return this.Parameter02 = val1
				case 'Parameter07':
				return this.Parameter07 = val1
				case 'Parameter06':
				return this.Parameter06 = val1
				case 'Parameter05':
				return this.Parameter05 = val1
				case 'Parameter03':
				return this.Parameter03 = val1
				case 'Parameter01':
				return this.Parameter01 = val1
			}
			// if(val = 'Manual'){
			// 	this.isMshow = val1
			// }
		},
		// 获取燃烧流程页面接口
		// 监听滚动条
		handleScroll() {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

			if (scrollTop >= 680) {
				this.isShow = true
			} else {
				this.isShow = false
			}
		},
		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
			window.removeEventListener("scroll", this.handleScroll)
		},

	},
}
